// action types
export const MAKE_REQUEST_BOARD = 'makeRequestBoard'
export const MAKE_REQUEST_LIST = 'makeRequestList'
export const MAKE_REQUEST_CARD = 'makeRequestCard'
export const GET_BOARDS = 'getBoards'
export const GET_LISTS = 'getLists'
export const GET_CARDS = 'getCards'
export const GET_BOARD_BY_ID = 'getBoardById'
export const GET_LIST_BY_ID = 'getListById'
export const GET_CARD_BY_ID = 'getCardById'
export const ERROR_BOARD = 'errorBoard'
export const ERROR_LIST = 'errorList'
export const ERROR_CARD = 'errorCard'
export const POST_REQUEST_BOARD = 'postRequestBoard'
export const POST_REQUEST_LIST = 'postRequestList'
export const POST_REQUEST_CARD = 'postRequestCard'
export const ADD_CARD = 'addCard'
export const ADD_BOARD = 'addBoard'
export const ADD_LIST = 'addList'
export const VALIDATION_ERROR_BOARD = 'validationErrorBoard'
export const VALIDATION_ERROR_LIST = 'validationErrorList'
export const VALIDATION_ERROR_CARD = 'validationErrorCard'
export const UPDATE_CARD = 'updateCard'
export const UPDATE_LIST = 'updateList'
export const UPDATE_BOARD = 'updateBoard'
export const GET_ACTIVITIES = 'getActivities'
export const ADD_ACTIVITY = 'addActivity'
export const ERROR_ACTIVITY = 'errorActivity'
export const DELETE_ACTIVITY = 'deleteActivity'
export const DELETE_CARD = 'deleteCard'
export const DELETE_LIST = 'deleteList'
export const DELETE_BOARD = 'deleteBoard'
export const VALIDATION_ERROR_ACTIVITY = 'validationErrorActivity'
export const GET_URLS = 'getUrls'
export const TOKEN_REQUEST = 'tokenRequest'
export const TOKEN_RESPONSE = 'tokenResponse'
export const USER_REQUEST = 'userRequest'
export const TOKEN_RESPONSE_ERROR = 'tokenResponseError'
export const GET_USER = 'getUser'
export const ERROR_USER = 'errorUser'
export const LOGIN_FAILED = 'loginFailed'
export const REGISTER_FAILED = 'registerFailed'
export const LOGIN_SUCCESS = 'loginSuccess'
export const REGISTER_SUCCESS = 'registerSuccess'
export const LOGIN_REQUEST = 'loginRequest'
export const REGISTER_REQUEST = 'registerRequest'
export const LOGOUT_USER = 'logoutUser'
